var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.newslettersMessages,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.descending,"page":_vm.pagination.page,"footer-props":_vm.pagination.footerProps,"items-per-page":_vm.pagination.rowsPerPage,"server-items-length":_vm.totalNewslettersMessages,"loading":_vm.loading,"show-select":"","show-expand":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "rowsPerPage", $event)}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(_vm._s(item.text))])]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" of "+_vm._s(itemsLength > _vm.pagination.rowsPerPage * _vm.pagination.page ? itemsLength - 1 + '+' : itemsLength)+" ")]}}])},[_c('v-progress-linear',{attrs:{"color":"blue","indeterminate":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }