



























import { Component, Vue, Watch } from 'vue-property-decorator';
import { INewsletterMessage, INewsletterSearchParams } from '@/interfaces';
import { dispatchGetNewsletterMessages } from '@/store/main/accessors';
import { readHasAdminAccess } from '@/store/main/accessors';

@Component
export default class NewslettersMessages extends Vue {
  public newsletterId: string | null = null;
  public search: string = '';
  public loading: boolean = false;
  public newslettersMessages: INewsletterMessage[] = [];
  public totalNewslettersMessages: number = 0;

  public range = [];
  public sender: string = '';
  public receiver: string = '';

  public pagination: { sortBy; descending; page; rowsPerPage; footerProps } = {
    sortBy: ['id'],
    descending: [false],
    page: 1,
    rowsPerPage: 20,
    footerProps: { 'items-per-page-options': [10, 20, 50, 100] },
  };

  public async updateNewslettersMessages(clearPagination?: boolean) {
    if (clearPagination) {
      this.pagination.page = 1;
    }
    this.loading = true;
    const data = await this.getDataFromApi();
    this.totalNewslettersMessages = data.total;
    this.newslettersMessages = data.items;
    this.loading = false;
  }

  @Watch('pagination', { deep: true })
  public async onPaginationChange(val) {
    await this.updateNewslettersMessages();
  }

  public get headers() {
    const headers = [
      {
        text: 'ID',
        value: 'id',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.receiver'),
        value: 'receiver',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.messageId'),
        value: 'message_id',
      },
      {
        text: '',
        value: 'data-table-expand',
      },
    ];

    return headers;
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async mounted() {
    await this.updateNewslettersMessages();
  }

  public async getDataFromApi() {
    const { sortBy, descending, page, rowsPerPage } = this.pagination;
    const skip = (page - 1) * rowsPerPage;
    const params: INewsletterSearchParams = {
      skip,
      limit: rowsPerPage,
      orderBy: sortBy[0],
      descending: descending[0],
      newsletterId: this.$attrs?.id,
    };
    return await dispatchGetNewsletterMessages(this.$store, params);
  }
}
